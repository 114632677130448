import React, { useState, useEffect } from "react";
// material
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { insertpinBadge, updatePinFunction } from "src/api/commonapi";
import Page from "src/components/Page";
import ChapterSelectDropDown from "src/components/ChapterSelectDropDown";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { getSuggestionListByCity } from "src/utils/common";
import { CSVLink } from "react-csv";
export function Suggestion() {
  let loginUser = JSON.parse(localStorage.getItem("userData"));

  const [suggestion, setSuggestion] = useState([]);
  const [newSuggestionList, setNewSuggestionList] = useState([]);

  const [searchData, setSearchData] = useState({
    // if admin is login the search Type is 0 and if city Amb is login then their city id is search Type
    city_id:
      loginUser?.u_type == 0 ||
      (loginUser?.u_type == 2 &&
        loginUser?.p_user_id == 1 &&
        loginUser?.management_p != 0)
        ? 0
        : loginUser?.city_id, // In City Data Get,
    chpt_id: 0,
    member: "",
    // startDate: "",
    // endDate: "",
  });

  const expo_columns = [
    // { label: "No", key: "b_id" },
    { label: "Chapter Name", key: "chpt_name" },
    { label: "Suggestion Given By", key: "m_name" },
    { label: "Suggestion", key: "suggestion" },
    { label: "Date", key: "date" },
  ];
  const columns = [
    // {
    //   name: "No",
    //   selector: (row) => row.s_id,
    //   sortable: true,
    //   width: "100px",
    // },
    {
      name: "Chapter Name",
      wrap: true,
      selector: (row) => row.chpt_name,
      width: "200px",
      sortable: true,
    },
    {
      name: "Suggestion Given By",
      wrap: true,
      selector: (row) => (
        <div
          style={{
            cursor: "pointer",
            overflow: "visible",
            whiteSpace: "pre-line",
          }}
        >
          {row.m_name}
        </div>
      ),
      width: "auto",
      sortable: true,
    },
    {
      name: "Suggestion",
      wrap: true,
      width: "auto",
      selector: (row) => (
        <div
          style={{
            cursor: "pointer",
            overflow: "visible",
            lineHeight: "1rem",
            whiteSpace: "pre-line",
          }}
        >
          {row.suggestion}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Date",
      wrap: true,
      width: "auto",
      selector: (row) => row.date,
      sortable: true,
    },

    // {
    //   name: "Action",
    //   sortable: true,

    //   selector: (row) => (
    //     <>
    //       <>
    //         <Tooltip title="Edit Business">
    //           <IconButton
    //             onClick={() => {
    //               getBusinnesAmount(row.b_amount, row.b_id);
    //             }}
    //           >
    //             <EditSharp color="warning" />
    //           </IconButton>
    //         </Tooltip>
    //         <Tooltip title="Delete Business">
    //           <IconButton
    //             onClick={() => {
    //               setDvisible({ mval: true, id: row.b_id });
    //             }}
    //           >
    //             <DeleteSharp color="error" />
    //           </IconButton>
    //         </Tooltip>
    //       </>
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    suggestionList();
  }, []);

  useEffect(() => {
    filterSuggestions();
  }, [suggestion, searchData]);

  const suggestionList = async () => {
    let resp = await getSuggestionListByCity(searchData);
    setSuggestion(resp);
  };

  const handleChapterSelect = async (selectedChapter) => {
    setSearchData({ ...searchData, chpt_id: selectedChapter.value });
  };

  const setNewSearchData = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };

  const filterSuggestions = () => {
    let nSuggestion = [];

    nSuggestion =
      suggestion &&
      suggestion.filter((sug) => {
        if (
          searchData.chpt_id == 0 ||
          (searchData.chpt_id != 0 && sug.c_id == searchData.chpt_id)
        ) {
          return sug;
        }
      });

    setNewSuggestionList(nSuggestion);
  };
  return (
    <>
      <Page title="LVB Suggestion | Local Vocal Business Group">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              LVB Suggestion
            </Typography>
          </Stack>

          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            <Grid item xs={12}>
              <ChapterSelectDropDown
                dropDownLabel={"Select Chapter"}
                selectedState={searchData}
                defaultOptionSelectAll={true}
                chapterKey="chpt_id"
                handleChange={handleChapterSelect}
              />
            </Grid>
            {/* <Grid item xs={5}>
              <TextField
                style={{ marginTop: "0px" }}
                fullWidth
                autoComplete="off"
                type="text"
                label="Member Name"
                name="m_name"
                value={searchData?.member}
                onChange={setNewSearchData}
              />
            </Grid> */}
            {/* <Grid item xs={2}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                isDisabled
                style={{ marginTop: "15px" }}
              >
                <DatePicker
                  type="date"
                  name="joindate"
                  label="Joining Date "
                  value={
                    searchData.startDate != ""
                      ? moment(searchData.startDate, "DD-MM-YYYY")
                      : null
                  }
                  inputFormat="dd/MM/yyyy"
                  onChange={(newDate) => {
                    setSearchData({
                      ...searchData,
                      startDate: moment(newDate, "DD-MM-YYYY").format(
                        "DD-MM-YYYY"
                      ),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                isDisabled
                style={{ marginTop: "15px" }}
              >
                <DatePicker
                  type="date"
                  name="joindate"
                  label="Joining Date "
                  value={
                    searchData.startDate != ""
                      ? moment(searchData.startDate, "DD-MM-YYYY")
                      : null
                  }
                  inputFormat="dd/MM/yyyy"
                  onChange={(newDate) => {
                    setSearchData({
                      ...searchData,
                      startDate: moment(newDate, "DD-MM-YYYY").format(
                        "DD-MM-YYYY"
                      ),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid> */}
            {/* <Grid
              item
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                padding: "0",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  console.log("ABCD");
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                onClick={async () => {
                  setSearchData({
                    city_id:
                      loginUser?.u_type == 0 ||
                      (loginUser?.u_type == 2 &&
                        loginUser?.p_user_id == 1 &&
                        loginUser?.management_p != 0)
                        ? 0
                        : loginUser?.city_id, // In City Data Get,
                    chpt_id: 0,
                    member: "",
                    startDate: "",
                    endDate: "",
                  });
                }}
              >
                Clear
              </Button>
            </Grid> */}
          </Grid>
          <Card
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              marginBottom: "25px",
            }}
          >
            <>
              <Button className="btn btn-export">
                <CSVLink
                  data={newSuggestionList}
                  headers={expo_columns}
                  filename={"Suggestion.csv"}
                  className="btn btn-primary test"
                  target="_blank"
                >
                  Export CSV
                </CSVLink>
              </Button>
              <DataTable
                style={{ overflow: "hidden" }}
                filter
                pagination
                highlightOnHover
                columns={columns}
                data={newSuggestionList}
              />
            </>
          </Card>
        </Container>
      </Page>
    </>
  );
}
